export const actions = {
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',
    SET_AUTH_CODE_ID: 'SET_AUTH_CODE_ID',
    SET_USER: 'SET_USER',
    SET_COUNTERPARTY_LIST: 'SET_COUNTERPARTY_LIST',
    SET_COUNTRY_LIST: 'SET_COUNTRY_LIST',
    SET_COUNTERPARTY: 'SET_COUNTERPARTY',
    AUTH_ERROR: 'AUTH_ERROR',
    RESTORE_PASSWORD_SUCCESS: 'RESTORE_PASSWORD_SUCCESS',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
    CHANGE_PASSWORD_RESULT: 'CHANGE_PASSWORD_RESULT',
    WAIT_RESPONSE: 'WAIT_RESPONSE_AUTH',
};

const defaultState = {
    booted: false,
    user: null,
    userId: null,
    counterparties: null,
    counterparty: null,
    counterpartyId: null,
    countries: null
}

const system = (state = defaultState, action) => {

    switch (action.type) {
        case actions.CLEAR_USER_DATA:
            return {...defaultState}

        case actions.SET_USER:
            return {
                ...state,
                user: action.user,
                userId: action.user.id,
            }
        case actions.SET_COUNTERPARTY_LIST:
            return {
                ...state,
                counterparties: action.items,
            }
        case actions.SET_COUNTRY_LIST:
            return {
                ...state,
                countries: action.items,
            }
        case actions.SET_COUNTERPARTY:
            return {
                ...state,
                counterparty: action.counterparty,
                counterpartyId: action.counterparty.id
            }
        default:
            return state
    }
}

export default system;
