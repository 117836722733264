import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {ConfigProvider} from "antd";
import reducer from './core/reducers';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import 'moment/locale/ru';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk'
import 'antd/dist/antd.less';
import './index.css'

const store = createStore(reducer, applyMiddleware(thunk))

if (process.env.NODE_ENV === 'development') {
    store.subscribe(() => {
        console.log("subscribe", store.getState())
    })
}

const importBuildTarget = () => {
    switch (process.env.REACT_APP_BUILD_TARGET) {
        case 'claim-app':
            return import("./app-claim/Routes.js");
        case 'office-app':
            return import("./app-office/Routes.js");
        default:
            return Promise.reject(
                new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
            );
    }
}

importBuildTarget().then(({default: Environment}) =>
    ReactDOM.render(
        <Provider store={store}>
            <ConfigProvider locale={ruRU}>
                <Environment/>
            </ConfigProvider>
        </Provider>,
        document.getElementById('root')
    )
);
